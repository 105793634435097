<template>
    <workspace-wrapper>
        <template #header>
            <free-spins-slot-header v-if="stateData.roundResult.isFreeSpin"
                                    v-bind="stateData.roundResult.bonusFreeSpins"
            />
        </template>

        <template #workspace>
            <pick-keno-workspace :state-data="stateData"/>
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import PickKenoWorkspace from "./PickKenoWorkspace";
import FreeSpinsSlotHeader from "../../Slots/Common/Header/FreeSpinsSlotHeader";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    roundResult: {
                        tickets: [{
                            bet: null,
                            place: null,
                            numbers: null,
                            totalWin: null,
                            orderCombination: {
                                matchCount: null,
                                matchNumbers: null,
                                win: null
                            },
                            disorderCombination: {
                                matchCount: null,
                                matchNumbers: null,
                                win: null
                            },
                            matchNumbers: null
                        }],
                        bonusFreeSpins: {
                            totalWin: null,
                            totalFreeSpins: null,
                            currentFreeSpin: null,
                            freeSpinsAwardedCount: null
                        },
                        isFreeSpin: null,
                        resultNumbers: null,
                        numbers: null,
                        covers: null
                    },
                    denom: null
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        PickKenoWorkspace,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped>

</style>
